<template>
  <div class="form_box">
    <el-form class="form-box-body" label-position="top" label-width="80px" :model="formLabelAlign">
      <div class="input-group" :class="{ 'loading': isLoading }">
        <input type="text" v-model="formLabelAlign.url" placeholder="请输入文件地址...">
        <span class="clear-icon" v-if="formLabelAlign.url" @click="clearInput">
          <i class="el-icon-circle-close"></i>
        </span>
        <button class="gradient" :disabled="isLoading" @click="parseSpeed">
          <span style="width: 100%;" v-if="!isLoading">加速</span>
          <span style="width: 100%;" v-else>
            <i class="el-icon-loading"></i> 加速中
          </span>
        </button>
      </div>
<!--      <el-form-item label="">-->
<!--        <el-input placeholder="请输入文件链接...." v-model="formLabelAlign.url"></el-input>-->

<!--      </el-form-item>-->
<!--      <el-button full icon="el-icon-s-promotion" type="primary" round>下载</el-button>-->

    </el-form>
  </div>
</template>

<script>
export default {
  name: "contentComponent",
  props:[
      'notice'
  ],
  data() {
    return {
      labelPosition: 'right',
      isLoading: false,
      formLabelAlign: {
        url: ''
      }
    };
  },
  methods:{
    clearInput() {
      this.formLabelAlign.url = '';
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    parseSpeed(){
      this.isLoading = true
      let url = this.formLabelAlign.url
      url = url.replace(/rsfile.cc|rsfile.org/, 'rosefile.net')
      const timestamp = new Date().getTime()
      const signature = this.Func.signature(url, timestamp, config.getSafeCode())
      this.Api.parseSpeed({
        timestamp: timestamp,
        signature: signature,
        url: url,
        safeCode: config.getSafeCode(),
        card: localStorage.getItem('card')
      }).then(r => {
        if(r.code == 200){
          this.$emit('downloadCallback', true, r.data)
        }else{
          this.$message.error(r.msg);
        }
        this.isLoading = false
      })

    }
  }
}
</script>

<style scoped>

.form_box {
  display: flex;
  justify-content: center;
}

.form-box-body {
  width: 50%;
}

.input-group {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

input {
  flex: 1;
  height: 45px;
  border: 1px solid #ccc;
  border-radius: 20px 0 0 20px;
  padding: 5px 30px 5px 10px;
}


button {
  height: 45px;
  width: 80px;
  border: none;
  border-radius: 0 20px 20px 0;
  background-color: #409EFF;
  color: #fff;
  padding: 0 10px;
  position: relative;
  z-index: 2;
}

button::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: -1px;
  width: 0;
  height: 0;
  border-style: solid;
  /*border-width: 15px 0 15px 15px;*/
  /*border-color: transparent transparent transparent #409EFF;*/
}

.clear-icon {
  position: absolute;
  top: 50%;
  right: 15%;
  transform: translateY(-50%);
  cursor: pointer;
}

.clear-icon i {
  font-size: 12px;
  color: #ccc;
}

.clear-icon:hover i {
  color: #999;
}

.clear-icon:hover {
  opacity: 0.8;
}


@media screen and (max-width: 768px) {

  .form-box-body {
    width: 80%;
  }

  button {
    /*width:80px;*/
  }


}

</style>