import service from './request';

//然后我们可以封装一些接口函数 比如说 登录的 注册的 首页的 分类的 轮播的 //但是要确认参数传的是get还是post请求


export function GetInfo() {
    return service.get('/web-app/notice/1')
}


export function UserLogin(data) {
    return service.post('/web-app/login', data)
}

export function parseSpeed(data) {
    return service.post('/web-app/exec', data)
}


export default { GetInfo, UserLogin, parseSpeed };