import axios from "axios";
import store from "../store";

const service = axios.create({
    baseURL: 'https://api.77word.com/',
    //baseURL: 'http://localhost:8081/',
    withCredentials: true,
    timeout: 30000//请求超时
})




service.interceptors.request.use(config=>{
    //每次发送请求要进行的公共操作  每次发送请求一般需要的操作一般都有 开启loading加载动画 token写在请求的头部 之类的
    //loading
    //最后的话一定要给他return出去 不return不执行
    config.headers['ver'] = 'qcy-helper-2.0.0';  // 设置请求头
    return config
},err=>{
    //请求的时候如果发生错误了， 这边直接给它抛出错误就行
    // throw new Error(err)抛出的是一个红色的报错方便我们查看寻找
    return {
        "code":err.code,
        "msg":err
    }
})


service.interceptors.response.use(response=>{
    //我们每次成功发送一个请求 它都会有响应的 参数也是两个
    //一般成功之后可以清除或关闭loading动画 还可以判断一些状态码
    //清除loading动画


    //判断状态码
    const res = response.data
    if (res.code && res.code !== 200) {
        // 登录超时,重新登录
        return res
    } else {
        return res
    }

},err=>{
    return {
        "code":err.code,
        "msg":err
    }
})


//导出  导出这个模块
export default service
