import AES_Decrypt from "./AES";
import CryptoJS from 'crypto-js'
import service from "./request2";

const Func = {
    saveCard(card){
        localStorage.setItem("card", card)
    },
    saveAria(aria){
        localStorage.setItem("aria", aria)
    },
    saveMotrix(motrix){
        localStorage.setItem("motrix", motrix)
    },
    getCard(){
        return localStorage.getItem("card")
    },
    getAria(){
        return localStorage.getItem("aria")
    },
    getMotrix(){
        return localStorage.getItem("motrix")
    },
    removeCard(){
        localStorage.removeItem("card")
    },
    decrypt(data){
        return AES_Decrypt(data)
    },
    isJSON(str) {
        if (typeof str !== 'string') {
            return false;
        }
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    },
    signature(url, timestamp, safeCode){
        return CryptoJS.MD5(`${url}|${timestamp}|wocaonima|${localStorage.getItem("card")}`).toString()
    },
    sendAria(url, referer, name, rpcUrl, token){
        if (!url) {
            return {
                "code":'Url is require',
                "msg":'URL IS REQUIRE'
            }
        }
        const params = {
            jsonrpc: '2.0',
            method: 'aria2.addUri',
            id: new Date().getTime(),
            params: [`token:${token}`, [url], { out: name }],
        };
        const headers = {
            'Content-Type': 'application/json',
        };
        return service.post(rpcUrl, params, { headers })
    },
    formatFileName(fileName){
        if(fileName == null || fileName == undefined || fileName === ''){
            return fileName
        }
        return fileName.replace(/[\\/:*?\"<>|]/g, '-');
    },
}

export default Func