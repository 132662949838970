<template>
  <div class="other">
    <el-divider content-position="left"></el-divider>
    <div class="text item">
      <div v-html="notice"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contentOtherComponent",
  props: [
      'notice'
  ],
}
</script>

<style scoped>

.other {


}

.el-card {
  width:100%; /* 设置卡片宽度 */
}

</style>