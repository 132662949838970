<template>
  <div class="wrapper">
    <header>
      <nav class="nav-menu">
        <ul class="nav-ul">
          <li class="nav-ul-li"><a class="menu-item-a" style="color: red" href="#"><i class="el-icon-error" /> 禁止访问违反中国法律内容</a></li>
          <li class="nav-ul-li"><a target="_blank" class="menu-item-a" :href="documentUrl"><i class="el-icon-question" /> 帮助</a></li>
          <li class="nav-ul-li"><a class="menu-item-a" @click="showDialog" href="#"><i class="el-icon-success" /> 授权</a></li>
        </ul>
      </nav>
    </header>
    <main>
      <transition name="fade">
        <div class="content" v-if="!isDialogVisible&&!isDownloadVisible">
          <el-image class="logo" :src="Logo"></el-image>
          <content-component @downloadCallback="downloadCallback" style="margin-top: 10px" />
          <content-other-component :notice="notice" />
        </div>
      </transition>
      <transition name="fade">
        <download-component @downloadCallback="downloadCallback" :file="file" v-if="!isDialogVisible&&isDownloadVisible"/>
      </transition>
    </main>
      <div class="dialog-mask" v-if="isDialogVisible&&!isDownloadVisible" @click="hideDialog"></div>
      <transition name="fade">
        <div class="dialog" v-if="isDialogVisible">
          <h2>授权</h2>
          <div style="display: flex;justify-content: center">

            <div class="card-box">
              <div style="margin-top: 15px;width: 90%">
                <el-input placeholder="请输入卡密" v-model="card" class="input-with-select">
                  <el-button :loading="btnLoading" @click="saveCard" slot="append" icon="el-icon-edit" type="primary">保存卡密</el-button>
                </el-input>
              </div>
            </div>
          </div>
          <el-divider content-position="left">Aria设置</el-divider>

          <div class="card-box">
            <div style="margin-top: 15px;width: 90%">
              <el-input placeholder="请输入Aria RPC URL" v-model="Aria.url" class="input-with-select">
                <el-button :loading="btnLoading" @click="saveSetting" slot="append" icon="el-icon-edit" type="primary">保存修改</el-button>
              </el-input>
              <el-input placeholder="请输入Aria RPC TOKEN" v-model="Aria.token" class="input-with-select">
                <el-button :loading="btnLoading" @click="saveSetting" slot="append" icon="el-icon-edit" type="primary">保存修改</el-button>
              </el-input>
            </div>
          </div>

          <el-divider content-position="left">Motrix设置</el-divider>

          <div class="card-box">
            <div style="margin-top: 15px;width: 90%">
              <el-input placeholder="请输入Motrix RPC URL" v-model="Motrix.url" class="input-with-select">
                <el-button :loading="btnLoading" @click="saveSetting" slot="append" icon="el-icon-edit" type="primary">保存修改</el-button>
              </el-input>
              <el-input placeholder="请输入Motrix RPC TOKEN" v-model="Motrix.token" class="input-with-select">
                <el-button :loading="btnLoading" @click="saveSetting" slot="append" icon="el-icon-edit" type="primary">保存修改</el-button>
              </el-input>
            </div>
          </div>

          <el-divider content-position="left"></el-divider>
          <div style="display: flex;justify-content: center">
            <div class="btn-box">
              <el-button  full :loading="btnLoading" @click="saveCard" type="success" icon="el-icon-s-custom">查看到期时间</el-button>
              <el-button  full  @click="hideDialog" type="success" icon="el-icon-view">返回</el-button>
            </div>
          </div>
        </div>
      </transition>
    <canvas class="particles"></canvas>
  </div>
</template>

<script>
import logo from '@/assets/logo.png'
import ContentComponent from "../components/contentComponent";
import ContentOtherComponent from "../components/contentOtherComponent";
import DownloadComponent from "../components/downloadComponent";

export default {
  components: {ContentOtherComponent, ContentComponent, DownloadComponent},
  data () {
    return {
      isDialogVisible: false,
      isDownloadVisible:false,
      card: '',
      btnLoading: false,
      notice: '未获取公告',
      documentUrl:'',
      Logo: logo,
      file:{

      },
      Aria:{
        url:'http://localhost:6800/jsonrpc',
        token:''
      },
      Motrix:{
        url:'http://localhost:16800/jsonrpc',
        token:''
      }
    }
  },
  mounted () {
    config.backGroundStyle()
    this.card = this.Func.getCard()
    const aria = this.Func.getAria()
    const motrix = this.Func.getMotrix()
    const isFirstOpen = localStorage.getItem("isFirst")
    const pcontent = localStorage.getItem("pcontent")
    if(aria == null || aria == '' || JSON.parse(aria).url == ''){
      this.Func.saveAria(JSON.stringify((this.Aria)))
    }
    if(motrix == null || motrix == '' || JSON.parse(motrix).url == ''){
      this.Func.saveMotrix(JSON.stringify((this.Motrix)))
    }
    console.log(aria, motrix)
    if(aria){
      this.Aria = JSON.parse(aria)
    }
    if(motrix){
      this.Motrix = JSON.parse(motrix)
    }
    this.Api.GetInfo({
      'safeCode': config.getSafeCode()
    }).then(r => {
      console.log(r)
      if(r.code !== 200){
        //失败
        this.$message.error(r.msg);
      }else {
        //成功
        this.notice = r.data.noticeContent
        // 将notice中的&lt;替换成< &gt;替换成> 避免XSS漏洞
        this.notice = this.notice.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
        this.documentUrl = r.data.updateurl

        if(!isFirstOpen){
          localStorage.setItem("isFirst", "0")
          localStorage.setItem("pcontent", r.data.noticeTitle)
          r.data.status === '0'?this.$alert(r.data.noticeTitle, '公告', {
            confirmButtonText: '我知道了',
          }):this.$message.success('欢迎使用');
        }else if(pcontent != r.data.noticeTitle){
          localStorage.setItem("isFirst", "0")
          localStorage.setItem("pcontent", r.data.noticeTitle)
          r.data.status === '0'?this.$alert(r.data.noticeTitle, '公告', {
            confirmButtonText: '我知道了',
          }):this.$message.success('欢迎使用');
        }else{
          this.$message.success('欢迎使用');
        }
      }
    })
  },
  methods: {
    saveSetting(){
      this.Func.saveAria(JSON.stringify((this.Aria)))
      this.Func.saveMotrix(JSON.stringify((this.Motrix)))
      this.$notify({
        title: '成功',
        message: `修改成功`,
        type: 'success'
      });
    },
    getRandomColor() {
      let color = '#'
      for (let i = 0; i < 6; i++) {
        color += Math.floor(Math.random() * 16).toString(16)
      }
      return color
    },
    downloadCallback(e, data){
      this.file = data
      this.isDownloadVisible = e
    },
    showDialog () {
      this.isDialogVisible = true
    },
    hideDialog () {
      this.isDialogVisible = false
    },
    saveCard(){
      this.btnLoading = true
      this.Func.saveCard(this.card)
      this.Api.UserLogin({
        'card': this.card
      }).then(r => {
        if(!r.code) {
          r = JSON.parse(this.Func.decrypt(r))
        }
        if(r.code !== 200){
          //失败
          this.$message.error(r.msg);
        }else {
          this.$notify({
            title: '登录成功',
            message: `欢迎使用,到期时间${new Date(r.data.endTime*1000).toLocaleString()}`,
            type: 'success'
          });
        }
        this.btnLoading = false
      })
    }
  }
}
</script>

<style>
.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 50%;
  z-index: 2;
  text-align: center;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;
  z-index: 1;
}

input:focus {
  outline: none;
}

#card-input {
  width: 80%;
  height: 36px;
  background: #fff;
  border-radius: 16px;
  border: 1px solid #4e6ef2;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
}

.btn-box {
  width: 80%;
  margin-bottom: 10px;
}

.btn-pro {
  height: 36px;
  width: 80%;
  background: #4e6ef2;
  border-radius: 16px;
  border: 1px solid #4e6ef2;
  color: #fff;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  display: block;
  margin: 10px;
}

.card-box {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.nav-menu {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}



.nav-ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-ul-li {
  margin: 0 10px;
}

.menu-item-a {
  display: block;
  color: #5913ce;
  text-decoration: none;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.menu-item-a:hover {
  background-color: #555;
}

main {
  position: absolute;
  top: 10%;
  left: 0;
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}


.content {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 80%;
  height: 80%;
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.el-button--block {
  display: block;
}

.el-button--full {
  width: 100%;
}

.logo {
  width: 40%;
  height: 30%;
}

@media screen and (max-width: 768px) {
  .dialog {
    width: 80%;
  }

  .logo {
    width: 100%;
    height: 20%;
  }

  .content {
    width: 95%;
    height: 95%;
  }

}
</style>
