<template>
  <el-card>
    <div slot="header" class="card-header">文件信息</div>
    <div class="card-content">
      <div v-if="file.name">文件名：{{ file.name }}</div>
      <div v-if="file.size">文件大小：{{ file.size }}</div>
      <div v-if="file.id">文件ID：{{ file.id }}</div>
      <el-divider></el-divider>
      <div class="download-line">
        <el-select v-model="selectedDown" placeholder="选择下载线路">
          <el-option :label="item.name" v-for="item in file.downs" :value="item.url"></el-option>
        </el-select>
      </div>
      <div class="download-buttons">
        <el-button type="primary" @click="downloadWithIDM">IDM下载</el-button>
        <el-button type="primary" @click="downloadWithAria(1)">Aria下载</el-button>
        <el-button type="primary" @click="downloadWithAria(2)">Motrix下载</el-button>
        <el-button type="primary" @click="downloadWithExtension">扩展下载</el-button>
        <el-button type="primary" @click="downloadWithThunder">迅雷下载</el-button>
      </div>
      <iframe width=0 height=0 :src="downLink">

      </iframe>
      <el-divider></el-divider>
      <el-button type="danger" @click="callback">返回</el-button>
    </div>
  </el-card>
</template>

<script>

export default {
  props:[
      'file'
  ],
  data() {
    return {
      selectedDown: '',
      downLink:'',
      Aria:null
    }
  },
  mounted() {
    const aria = localStorage.getItem('aria')
    if(this.Func.isJSON(aria)) {
      this.Aria = JSON.parse(aria)
    }else {
      this.$message.error('Aria2配置错误')
    }

  },
  methods: {
    downloadWithIDM() {
      // IDM下载逻辑
      const bt = btoa(`${this.selectedDown}|||||||${this.file.referer}|||||||${encodeURIComponent(this.file.name)}`)
      window.location.href = 'qcypro://' + bt + 'qcyproend'
    },
    downloadWithAria(e) {
      // Aria下载逻辑
      let rpc_url = ''
      let rpc_token = ''
      if(e === 1){
        rpc_url = JSON.parse(this.Func.getAria()).url
        rpc_token = JSON.parse(this.Func.getAria()).token
      }else if(e === 2){
        rpc_url = JSON.parse(this.Func.getMotrix()).url
        rpc_token = JSON.parse(this.Func.getMotrix()).token
      }
      this.$axios
          .post(rpc_url, {
            jsonrpc: "2.0",
            method: "aria2.addUri",
            id: "QXJpYU5nXzE2NTY1MDU4NjVfMC4yNDQwMTMxOTA4NjY3NTY=",
            params: [
              "token:" + rpc_token,
              [this.selectedDown],
              {
                out: this.Func.formatFileName(this.file.name),
                referer: this.file.referer,
                header: [
                  "user-Agent:Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Safari/537.36",
                ],
              },
            ],
          })
          .then((result) => {
            console.log(result)
            this.$message.success("推送成功");
          })
          .catch((err) => {
            console.log(err)
            this.$message.error(err)
          });

      if(this.Aria == null){
        this.$message.error('Aria2配置错误')
        return
      }
    },
    downloadWithExtension() {
      // 扩展下载逻辑
      window.open(this.selectedDown)
    },
    downloadWithThunder() {
      // 迅雷下载逻辑
      // 创建单个任务
      // 创建单个任务

      thunderLink.newTask({
        downloadDir: "", // 指定当前任务的下载目录名称，迅雷会在用户剩余空间最大的磁盘根目录中创建这个目录。【若不填此项，会下载到用户默认下载目录】
        tasks: [
          {
            name: this.file.name, // 指定下载文件名（含扩展名）。【若不填此项，将根据下载 URL 自动获取文件名】
            url: this.selectedDown, // 指定下载地址【必填项】
          },
        ],
        referer: this.file.referer, // 指定迅雷发起下载请求时上报的引用页【一般不必填写，除非某些下载地址的服务器会判断引用页】
        userAgent:
            "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Safari/537.36", // 指定迅雷发起下载请求时上报的UA【一般不必填写，除非某些下载地址的服务器会判断UA】
      });

      // window.thunderLink.newTask({
      //   downloadDir: '', // 指定当前任务的下载目录名称，迅雷会在用户剩余空间最大的磁盘根目录中创建这个目录。【若不填此项，会下载到用户默认下载目录】
      //   tasks: [{
      //     name: this.file.name, // 指定下载文件名（含扩展名）。【若不填此项，将根据下载 URL 自动获取文件名】
      //     url: this.selectedDown // 指定下载地址【必填项】
      //   }],
      //   referer:this.file.referer, // 指定迅雷发起下载请求时上报的引用页【一般不必填写，除非某些下载地址的服务器会判断引用页】
      // });
    },
    callback(){
      this.$emit('downloadCallback',false)
    }
  }
}
</script>

<style scoped>
.card-header {
  font-size: 18px;
  font-weight: bold;
}
.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}
.download-line {
  margin-top: 20px;
}
.download-buttons {
  margin-top: 20px;
}
</style>
