import { render, staticRenderFns } from "./downloadComponent.vue?vue&type=template&id=221b59fa&scoped=true&"
import script from "./downloadComponent.vue?vue&type=script&lang=js&"
export * from "./downloadComponent.vue?vue&type=script&lang=js&"
import style0 from "./downloadComponent.vue?vue&type=style&index=0&id=221b59fa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "221b59fa",
  null
  
)

export default component.exports